// Remove Focus Boxes
select:focus {
  outline: $select-focus;
}

button:focus {
  outline: none;
  background-color: $button-background-focus;
}

label {
  font-size: $label-font-size;
  color: $input-border-color;
}

.has-error {
  border: 1px solid #a94442 !important;
}

.alert-message {
  margin: 5px;
  color: #a94442;
}

.sortable-message {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  margin: 0;
  padding: 12px 15px;
}

@import 'input-fields';
@import 'radio-buttons';
@import 'checkboxes';
@import 'switches';
@import 'select';
@import 'file-input';
@import 'range';
