.itemlayout-td {
  position: relative;
  &__content {
    width: 80%;
    margin: 0 auto;
  }
  &__menu {
    position: absolute;
    top: 0;
    right: 0;
    i:hover{
      opacity: 0.7;
    }
  }
}

.popup {
  &.itemlayout {
    h5 {
      margin: 10px;
      padding: 10px 15px;
      color: white;
      font-size: 20px;
    }
  }
}
